import React, { useState } from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

function AppFaq() {
  const [panelOpen, setPanelOpen] = useState("1");

  const handlePanelChange = (key) => {
    setPanelOpen(key);
  };

  return (
    <div id="faq" className="block faqBlock">
      <div className="container-fluid">
        <div className="titleHolder flexy">
          <span style={{ "--i": 1 }}>F</span>
          <span style={{ "--i": 2 }}>r</span>
          <span style={{ "--i": 3 }}>e</span>
          <span style={{ "--i": 4 }}>q</span>
          <span style={{ "--i": 5 }}>u</span>
          <span style={{ "--i": 6 }}>e</span>
          <span style={{ "--i": 7 }}>n</span>
          <span style={{ "--i": 8 }}>t</span>
          <span style={{ "--i": 9 }}>l</span>
          <span style={{ "--i": 10 }}>y</span>&nbsp;&nbsp;
          <span style={{ "--i": 11 }}>A</span>
          <span style={{ "--i": 12 }}>s</span>
          <span style={{ "--i": 13 }}>k</span>
          <span style={{ "--i": 14 }}>e</span>
          <span style={{ "--i": 15 }}>d</span>&nbsp;&nbsp;
          <span style={{ "--i": 16 }}>Q</span>
          <span style={{ "--i": 17 }}>u</span>
          <span style={{ "--i": 18 }}>e</span>
          <span style={{ "--i": 19 }}>s</span>
          <span style={{ "--i": 20 }}>t</span>
          <span style={{ "--i": 21 }}>i</span>
          <span style={{ "--i": 22 }}>o</span>
          <span style={{ "--i": 23 }}>n</span>
          <span style={{ "--i": 24 }}>s</span>
        </div>
        <Collapse
          activeKey={panelOpen}
          onChange={handlePanelChange}
          expandIcon={({ isActive }) => (
            <i
              className={`fas fa-chevron-${isActive ? "up" : "down"}`}
              style={{
                fontSize: "24px",
                transform: `rotate(${isActive ? "180deg" : "0deg"})`, // Apply rotation
                transition: "transform 0.3s ease", // Add a transition
              }}
            />
          )}
        >
          <Panel
            header="How do I apply your patches to my clothing or accessories?"
            key="1"
            className="custom-panel-header"
          >
            <p>
              Applying our patches is easy! Most of our patches come with
              adhesive backing for simple iron-on or sew-on application.
              Detailed instructions are included with each patch.
            </p>
          </Panel>
          <Panel
            header="Can I order custom-designed patches for my brand or event?"
            key="2"
            className="custom-panel-header"
          >
            <p>
              Absolutely! We offer custom patch design services. You can choose
              the size, shape, colors, and design to match your brand or event
              perfectly.
            </p>
          </Panel>
          <Panel
            header="Are your patches suitable for different fabric types?"
            key="3"
            className="custom-panel-header"
          >
            <p>
              Yes, our patches are designed to adhere to a wide range of
              fabrics, including denim, cotton, leather, and more. They work
              well on most textiles.
            </p>
          </Panel>
          <Panel
            header="What sets your patches apart in terms of quality?"
            key="4"
            className="custom-panel-header"
          >
            <p>
              Our patches are crafted with precision, using high-quality
              materials to ensure durability and longevity. We prioritize
              quality in every aspect of our production process.
            </p>
          </Panel>
          <Panel
            header="Do you offer bulk ordering options for businesses?"
            key="5"
            className="custom-panel-header"
          >
            <p>
              Yes, we offer bulk ordering to accommodate businesses and
              organizations. You can order in larger quantities at competitive
              prices.
            </p>
          </Panel>
          <Panel
            header="How can I stay updated on new patch designs and promotions?"
            key="6"
            className="custom-panel-header"
          >
            <p>
              To stay in the loop, subscribe to our newsletter and follow us on
              social media. We regularly share updates on new designs,
              promotions, and fashion tips.
            </p>
          </Panel>
          <Panel
            header="What is TurnAround time for Digitizing?"
            key="7"
            className="custom-panel-header"
          >
            <p>
              We are providing minimum TurnAround time, is about 1 to 2 hours.
            </p>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}

export default AppFaq;
