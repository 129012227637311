import React from "react";
import { Carousel } from "antd";
import vector2 from "../../assets/images/vector2.jpeg";
import vector3 from "../../assets/images/vector3.jpeg";
import vector4 from "../../assets/images/vector4.jpeg";
import vector5 from "../../assets/images/vector5.jpeg";
import digitizing1 from "../../assets/images/digitizing1.jpeg";
import digitizing2 from "../../assets/images/digitizing2.jpeg";
import digitizing3 from "../../assets/images/digitizing3.jpeg";
import digitizing5 from "../../assets/images/digitizing5.jpeg";
import digitizing6 from "../../assets/images/digitizing6.jpeg";
import digitizing7 from "../../assets/images/digitizing7.jpeg";
import digitizing8 from "../../assets/images/digitizing8.jpeg";
import digitizing9 from "../../assets/images/digitizing9.jpeg";
import digitizing10 from "../../assets/images/digitizing10.jpeg";
import patches1 from "../../assets/images/patches1.jpeg";
import patches2 from "../../assets/images/patches2.jpeg";
import patches3 from "../../assets/images/patches3.jpeg";
import patches4 from "../../assets/images/patches4.jpeg";
import patches5 from "../../assets/images/patches5.jpeg";
import engraving1 from "../../assets/images/engraving1.jpg";
import engraving2 from "../../assets/images/engraving2.jpg";
import engraving3 from "../../assets/images/engraving3.jpg";
import engraving4 from "../../assets/images/engraving4.jpg";
import OtherServices from "./otherServices";

function Portfolio() {
  const settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const digisettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div id="portfolio" className="block portfolioImage">
        <div className="container-fluid">
          <div className="titleHolder flexy" id="animated-element">
            <span style={{ "--i": 1 }}>P</span>
            <span style={{ "--i": 2 }}>o</span>
            <span style={{ "--i": 3 }}>r</span>
            <span style={{ "--i": 4 }}>t</span>
            <span style={{ "--i": 5 }}>f</span>
            <span style={{ "--i": 6 }}>o</span>
            <span style={{ "--i": 7 }}>l</span>
            <span style={{ "--i": 8 }}>i</span>
            <span style={{ "--i": 9 }}>o</span>
          </div>

          <div class="containers">
            <h2 class="titles">
              <span class="title-word title-word-1">Our</span>&nbsp;
              <span class="title-word title-word-2">Latest</span>&nbsp;
              <span class="title-word title-word-3">
                <u>Vector</u>
              </span>
              &nbsp;
              <span class="title-word title-word-4">Designs</span>&nbsp;
            </h2>
          </div>
          <Carousel {...settings}>
            <div className="carouselItem">
              <img className="img-border" src={vector2} alt="Vector" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={vector3} alt="Vector" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={vector4} alt="Vector" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={vector5} alt="Vector" />
            </div>
          </Carousel>
          <div class="containers">
            <h2 class="titles">
              <span class="title-word title-word-1">Our</span>&nbsp;
              <span class="title-word title-word-2">Latest</span>&nbsp;
              <span class="title-word title-word-3">
                <u>Digitizing</u>
              </span>
              &nbsp;
              <span class="title-word title-word-4">Designs</span>&nbsp;
            </h2>
          </div>

          <Carousel {...digisettings}>
            <div className="carouselItem">
              <img className="img-border" src={digitizing1} alt="Digitizing" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={digitizing2} alt="Digitizing" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={digitizing3} alt="Digitizing" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={digitizing5} alt="Digitizing" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={digitizing6} alt="Digitizing" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={digitizing7} alt="Digitizing" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={digitizing8} alt="Digitizing" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={digitizing9} alt="Digitizing" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={digitizing10} alt="Digitizing" />
            </div>
          </Carousel>
          <div class="containers">
            <h2 class="titles">
              <span class="title-word title-word-1">Our</span>&nbsp;
              <span class="title-word title-word-2">Latest</span>&nbsp;
              <span class="title-word title-word-3">
                <u>Patches</u>
              </span>
              &nbsp;
              <span class="title-word title-word-4">Designs</span>&nbsp;
            </h2>
          </div>

          <Carousel {...digisettings}>
            <div className="carouselItem">
              <img className="img-border" src={patches1} alt="Patches" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={patches2} alt="Patches" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={patches3} alt="Patches" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={patches4} alt="Patches" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={patches5} alt="Patches" />
            </div>
          </Carousel>

          <div class="containers">
            <h2 class="titles">
              <span class="title-word title-word-1">Our</span>&nbsp;
              <span class="title-word title-word-2">Latest</span>&nbsp;
              <span class="title-word title-word-3">
                <u>Engraving</u>
              </span>
              &nbsp;
              <span class="title-word title-word-4">Designs</span>&nbsp;
            </h2>
          </div>

          <Carousel {...settings}>
            <div className="carouselItem">
              <img className="img-border" src={engraving1} alt="Engraving" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={engraving2} alt="Engraving" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={engraving3} alt="Engraving" />
            </div>
            <div className="carouselItem">
              <img className="img-border" src={engraving4} alt="Engraving" />
            </div>
          </Carousel>
        </div>
      </div>
      <div>
        <OtherServices />
      </div>
    </>
  );
}

export default Portfolio;
