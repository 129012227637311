import React from "react";

import AppHero from "../components/homeComponent/hero";
import AppAbout from "../components/homeComponent/about";
// import AppFeature from "../components/homeComponent/feature";
import AppWorks from "../components/homeComponent/works";
import AppFaq from "../components/homeComponent/faq";
import AppPricing from "../components/homeComponent/pricing";
import AppContact from "../components/homeComponent/contact";
import Portfolio from "../components/homeComponent/portfolio";

function AppHome() {
  return (
    <div className="main">
      <AppHero />
      <AppAbout />
      {/* <AppFeature/> */}
      <Portfolio />
      <AppWorks />
      <AppFaq />
      <AppPricing />
      <AppContact />
    </div>
  );
}

export default AppHome;
