import React from "react";
import {
  ReadOutlined,
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Form, Input, Checkbox, message } from "antd";
const { TextArea } = Input;

function AppContact() {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const formData = {
      ...values,
      access_key: "30af52ed-d86d-445d-92c4-ec9ee96b1c11",
    };

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await res.json();

      if (res.ok && result.success) {
        message.success("Your data has been Submitted Successfully! 🥳");
        form.resetFields();
      } else {
        message.error("There was an error Submitting your Data. 🙁");
      }
    } catch (error) {
      console.error("Fetch error:", error);
      message.error("Failed to submit the form. Please try again. 🙄");
    }
  };

  return (
    <div id="contact" className="block contactBlock">
      <div className="container-fluid">
        <div className="titleHolder flexy">
          <span style={{ "--i": 1 }}>G</span>
          <span style={{ "--i": 2 }}>e</span>
          <span style={{ "--i": 3 }}>t</span>&nbsp;&nbsp;
          <span style={{ "--i": 4 }}>I</span>
          <span style={{ "--i": 5 }}>n</span>&nbsp;&nbsp;
          <span style={{ "--i": 6 }}>T</span>
          <span style={{ "--i": 7 }}>o</span>
          <span style={{ "--i": 8 }}>u</span>
          <span style={{ "--i": 9 }}>c</span>
          <span style={{ "--i": 10 }}>h</span>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          name="contact_form"
          className="contact-form"
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="fullname"
            rules={[
              {
                required: true,
                message: "Please enter your full name!",
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Full Name"
              prefix={
                <UserOutlined
                  className="phone-shake"
                  style={{ color: "#F55555" }}
                />
              }
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Email Address"
              prefix={
                <MailOutlined
                  className="phone-shake"
                  style={{ color: "#F55555" }}
                />
              }
            />
          </Form.Item>
          <Form.Item name="telephone">
            <Input
              type="number"
              placeholder="Telephone"
              prefix={
                <PhoneOutlined
                  className="phone-shake"
                  style={{ transform: "rotate(100deg)", color: "#F55555" }}
                />
              }
            />
          </Form.Item>
          <Form.Item name="subject">
            <Input
              type="text"
              placeholder="Subject"
              prefix={
                <ReadOutlined
                  className="phone-shake"
                  style={{ color: "#F55555" }}
                />
              }
            />
          </Form.Item>
          <Form.Item name="message">
            <TextArea placeholder="Message" />
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              noStyle
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject("Should accept agreement"),
                },
              ]}
            >
              <Checkbox>I agree with terms and conditions.</Checkbox>
            </Form.Item>
          </Form.Item>
          <br></br>
          <Form.Item>
            <div className="wraps">
              <button className="submit-button" type="submit">
                Submit
              </button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AppContact;
