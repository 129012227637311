import React from "react";

import { List, Card, Button } from "antd";

const data = [
  {
    title: "Digitizing",
    subTitle: "(Left Chest/Cap)",
    content: [
      {
        price:
          "• Digitizing services starting at $8 (simple) to $75 (complex).",
        turnaround: "• Fast turnaround: 1-2 hours.",
        medium: "",
        contact:
          "• Contact us for a quote and let us deliver high-quality digital files for your embroidery needs!",
      },
    ],
  },
  {
    title: "Vectorizing",
    subTitle: "",
    content: [
      {
        price: "• Simple Vector services starting at $7 to 10$.",
        turnaround: "• Medium starting at $15 to $25.",
        medium: "• Complex starting at $20.",
        contact: "• Advance vector Art $40 to max $180",
      },
    ],
  },
  {
    title: "All Kinds of Patches",
    subTitle: "(Each Patch is about to 5usd)",
    content: [
      {
        price: "• No Setup FeeD.",
        turnaround: "• 5usd for each patch (min order 20)",
        medium: "• Free Pre-Production Sample (Image)D.",
        contact: "• Delivery Within a WeekD.",
        heat: "• Free Heat-Seal BackingD.",
        shipping: "• Free ShippingD.",
      },
    ],
  },
  {
    title: "Engraving",
    subTitle: "",
    content: [
      {
        price: "• Simple Engraving $10 to $25.",
        turnaround: "• Medium 25$ to 50$.",
        medium: "• Complex 25$ to 100$",
      },
    ],
  },
];

function AppPricing() {
  return (
    <div id="pricing" className="block pricingBlock bgGray">
      <div className="container-fluid">
        <div className="titleHolder flexy">
          <span style={{ "--i": 1 }}>P</span>
          <span style={{ "--i": 2 }}>r</span>
          <span style={{ "--i": 3 }}>i</span>
          <span style={{ "--i": 4 }}>c</span>
          <span style={{ "--i": 5 }}>e</span>
        </div>
        <br />
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 4,
            xxl: 4,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card className="main-pricing fade-in-button" title={item.title}>
                {" "}
                {item.subTitle}
                <p>{item.content[0].price}</p>
                <p>{item.content[0].turnaround}</p>
                <p>{item.content[0].medium}</p>
                <p>{item.content[0].contact}</p>
                <p>{item.content[0].heat}</p>
                <p>{item.content[0].shipping}</p>
                <Button
                  type="primary"
                  style={{ background: "#F55555" }}
                  size="large"
                >
                  <i className="fab fa-telegram-plane"></i> Get Started
                </Button>
              </Card>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}

export default AppPricing;
