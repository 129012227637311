import React from "react";
import { Carousel } from "antd";
import resin1 from "../../assets/images/resin1.jpeg";
import resin2 from "../../assets/images/resin2.jpeg";
import resin3 from "../../assets/images/resin3.jpeg";
import resin4 from "../../assets/images/resin4.jpeg";
import resin5 from "../../assets/images/resin5.jpeg";

function OtherServices() {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="block portfolioImage">
      <div className="container-fluid">
        <div className="titleHolder flexy" id="animated-element">
          <span style={{ "--i": 1 }}>O</span>
          <span style={{ "--i": 2 }}>t</span>
          <span style={{ "--i": 3 }}>h</span>
          <span style={{ "--i": 4 }}>e</span>
          <span style={{ "--i": 5 }}>r</span>&nbsp;
          <span style={{ "--i": 6 }}>S</span>
          <span style={{ "--i": 7 }}>e</span>
          <span style={{ "--i": 8 }}>r</span>
          <span style={{ "--i": 9 }}>v</span>
          <span style={{ "--i": 10 }}>i</span>
          <span style={{ "--i": 11 }}>c</span>
          <span style={{ "--i": 12 }}>e</span>
          <span style={{ "--i": 13 }}>s</span>
        </div>

        <div class="containers">
          <h2 class="titles">
            <span class="title-word title-word-1">Our</span>&nbsp;
            <span class="title-word title-word-2">Latest</span>&nbsp;
            <span class="title-word title-word-3">
              <u>Resin</u>
            </span>
            &nbsp;
            <span class="title-word title-word-4">Art Work</span>&nbsp;
          </h2>
        </div>
        <Carousel {...settings}>
          <div className="carouselItem">
            <img className="img-border" src={resin1} alt="Resin Art Work" />
          </div>
          <div className="carouselItem">
            <img className="img-border" src={resin2} alt="Resin Art Work" />
          </div>
          <div className="carouselItem">
            <img
              className="img-border"
              src={resin3}
              style={{ width: "100%" }}
              alt="Resin Art Work"
            />
          </div>
          <div className="carouselItem">
            <img className="img-border" src={resin4} alt="Resin Art Work" />
          </div>
          <div className="carouselItem">
            <img className="img-border" src={resin5} alt="Resin Art Work" />
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default OtherServices;
