import React from "react";
import {
  ArrowUpOutlined,
  FacebookOutlined,
  GoogleOutlined,
  InstagramOutlined,
  MailOutlined,
  EnvironmentOutlined,
  WhatsAppOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import paypal from "../../assets/images/paypal.jpg";
import payoneer from "../../assets/images/payoneer.jpg";
import { BackTop, Row, Col } from "antd";

function AppFooter() {
  return (
    <div className="container-fluid">
      <div className="footer">
        <Row>
          <Col
            xl={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
          >
            <h1 className="hide">Contact Us</h1>
            <div className="location">
              <p style={{ textTransform: "initial" }}>
                <EnvironmentOutlined style={{ color: "red" }} />
                &nbsp;Karachi, Pakistan
              </p>
              <p style={{ textTransform: "lowercase" }}>
                <MailOutlined style={{ color: "#FF512F" }} />
                &nbsp;Info@digistitch.store
              </p>
              <p>
                <WhatsAppOutlined
                  className="phone-shake"
                  style={{ color: "green", fontSize: "1.3rem" }}
                />
                &nbsp;+92-309-2501268
              </p>
              <p>
                <PhoneOutlined
                  className="phone-shake"
                  style={{ color: "blue", fontSize: "1.3rem" }}
                />
                &nbsp;+1 254 326 1323
              </p>
            </div>
          </Col>

          <Col
            xl={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
          >
            <div>
              <p className="hide">Payment Partners</p>
              <img
                style={{ height: "3.5rem", borderRadius: "10px" }}
                src={paypal}
                alt="Paypal"
              ></img>
              &nbsp;
              <img
                style={{ height: "3.5rem", borderRadius: "10px" }}
                src={payoneer}
                alt="Payoneer"
              ></img>
            </div>
          </Col>
        </Row>
        <ul className="socials">
          <li>
            <a href="https://www.digistitch.store/" target="_blank">
              {" "}
              <GoogleOutlined />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61561506538320&mibextid=ZbWKwL/"
              target="_blank"
            >
              <FacebookOutlined />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/profile.php?id=61561506538320&mibextid=ZbWKwL/"
              target="_blank"
            >
              <InstagramOutlined />
            </a>
          </li>
        </ul>
        <div className="copyright">Copyright &copy; 2024 Digi Stitch</div>

        <BackTop>
          <div className="goTop">
            <ArrowUpOutlined />
          </div>
        </BackTop>
      </div>
    </div>
  );
}

export default AppFooter;
