import React, { useEffect, useState } from "react";
import { Col, Row, Carousel } from "antd";
import { NotificationOutlined } from "@ant-design/icons";

const items = [
  {
    key: "1",
    welcome: "Welcome to our Digi Stitch",
    title: "Discover Our Unique Collection",
    content:
      "Welcome to Digi Stitch, Where creativity knows no bounds! Dive into our world of clothing patches, and discover a unique collection that's designed to elevate your style. Our passion for fashion is evident in every meticulously crafted patch, each waiting to tell its own story on your favorite clothing items. From classic designs to trendy and innovative concepts, we've got the perfect patch for every fashion enthusiast. Explore our diverse range, and let your individuality shine through with our exceptional patches.",
  },
  {
    key: "2",
    welcome: "Welcome to our Digi Stitch",
    title: "Quality Craftsmanship, Unmatched Style",
    content:
      " At Digi Stitch, we take pride in offering more than just patches; we deliver an experience of quality craftsmanship and unparalleled style. Our commitment to excellence is woven into every thread and stitch of our patches, ensuring they not only enhance your attire but also stand the test of time. Whether you're a fashion-forward trendsetter or a vintage-loving soul, our patches are designed to meet your unique fashion needs. Welcome to a world where quality meets style, and where your clothing becomes a canvas for personal expression.",
  },
  {
    key: "3",
    welcome: "Welcome to our Digi Stitch",
    title: "Embrace Your Imagination",
    content:
      "Welcome to a realm where your creativity knows no bounds! Digi Stitch is your gateway to a world of endless possibilities. With our vast array of clothing patches, you can unleash your imagination and transform your wardrobe into a reflection of your personality. From quirky and fun to elegant and sophisticated, our patches cater to every taste and occasion. Join us in the journey of self-expression through fashion, and let your imagination run wild as you curate a wardrobe that tells your unique story. Welcome to the world of limitless style possibilities at Digi Stitch.",
  },
];

function AppHero() {
  const [animateText, setAnimateText] = useState(true);

  useEffect(() => {
    // Delay the animation on page load for a smoother effect
    const delay = setTimeout(() => {
      setAnimateText(false);
    }, 1000); // Adjust the delay as needed

    // Clear the timeout on component unmount
    return () => clearTimeout(delay);
  }, []);

  return (
    <div
      id="hero"
      className="heroBlock"
      style={{ position: "relative", height: "100vh", overflow: "hidden" }}
    >
      <Carousel
        autoplay
        autoplaySpeed={5000}
        style={{ position: "relative", zIndex: 1 }}
      >
        {items.map((item) => (
          <Row key={item.key}>
            <Col
              xxl={{ span: 24, offset: 6 }}
              xl={{ span: 24, offset: 5 }}
              lg={{ span: 20, offset: 4 }}
              md={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              xs={{ span: 20, offset: 2 }}
              xss={{ span: 20, offset: 2 }}
            >
              <div className="container-fluid">
                <br />
                <div
                  className={`content ${
                    animateText ? "slide-from-left-element" : ""
                  }`}
                >
                  <p
                    style={{
                      fontSize: "2.3rem",
                      fontFamily: "helvetica",
                      fontWeight: "bold",
                      color: "#F55555",
                    }}
                    className="fade-in heading"
                  >
                    <span className="animate-charcter">
                      <i>{item.welcome}</i>
                    </span>
                  </p>
                  <p
                    style={{ fontSize: "1.4rem", fontWeight: "bold" }}
                    className="fade-in title"
                  >
                    <NotificationOutlined
                      className="phone-shake"
                      style={{ color: "#F55555", fontSize: "26px" }}
                    />
                    &nbsp;&nbsp;<i>{item.title}</i>
                  </p>
                  <p
                    style={{ fontSize: "1.2rem", fontFamily: "helvetica" }}
                    className="fade-in paragraph"
                  >
                    <i>{item.content}</i>
                    <br></br>
                    <br></br>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </Carousel>
    </div>
  );
}

export default AppHero;
