import React from "react";
import { Row, Col } from "antd";
import {
  TrophyOutlined,
  SmileOutlined,
  CrownOutlined,
  UserOutlined,
  SketchOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

const items = [
  {
    key: "1",
    icon: <SmileOutlined className="shaking-rotating-text" />,
    title: "Quality First",
    content:
      "Our patches are crafted with precision and attention to detail, using the finest materials to ensure longevity and durability.",
  },
  {
    key: "2",
    icon: <TrophyOutlined className="shaking-rotating-text" />,
    title: "Unique Designs",
    content:
      "We offer a diverse range of patches, from classic to contemporary, ensuring there's something for every style and occasion.",
  },
  {
    key: "3",
    icon: <CrownOutlined className="shaking-rotating-text" />,
    title: "Endless Possibilities",
    content:
      "We encourage creativity by providing the tools for self-expression through fashion, allowing you to personalize and revamp your clothing effortlessly.",
  },
  {
    key: "4",
    icon: <UserOutlined className="shaking-rotating-text" />,
    title: "Customer-Centric Approach",
    content:
      "Your satisfaction is our top priority. We strive to provide excellent customer service, ensuring a seamless shopping experience every time.",
  },
  {
    key: "5",
    icon: <SketchOutlined className="shaking-rotating-text" />,
    title: "Fashion with Purpose",
    content:
      "Beyond style, we believe in sustainability and ethical practices, making conscious choices in our production processes to minimize environmental impact.",
  },
  {
    key: "6",
    icon: <GlobalOutlined className="shaking-rotating-text" />,
    title: "Unwavering Commitment",
    content:
      "At Digi Stitch, we're dedicated to excellence in everything we do. Our commitment means quality patches, exceptional service, and your complete satisfaction, always.",
  },
];

function AppAbout() {
  return (
    <div id="about" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder flexy">
          <span style={{ "--i": 1 }}>A</span>
          <span style={{ "--i": 2 }}>b</span>
          <span style={{ "--i": 3 }}>o</span>
          <span style={{ "--i": 4 }}>u</span>
          <span style={{ "--i": 5 }}>t</span>&nbsp;&nbsp;
          <span style={{ "--i": 6 }}>U</span>
          <span style={{ "--i": 7 }}>s</span>
        </div>
        <div className="contentHolder">
          <p>
            At Digi Stitch, we are more than just a brand; we are a
            passion-driven team dedicated to redefining the way you express
            yourself through fashion. With a foundation built on quality
            craftsmanship, innovation, and a love for style, we have become a
            trusted name in the world of clothing patches. Here's what sets us
            apart:{" "}
          </p>
        </div>
        <Row gutter={[16, 16]}>
          {items.map((item) => {
            return (
              <Col md={{ span: 8 }} key={item.key}>
                <div className="content">
                  <div className="icon">{item.icon}</div>
                  <h3 className="typing-text">{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}
export default AppAbout;
