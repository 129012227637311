import React from "react";
import video from "../../assets/video/workvideo.mp4";
import { Button, Modal } from "antd";

class AppWorks extends React.Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div id="works" className="block worksBlock">
        <div className="container-fluid">
          <div className="titleHolder">
            <center>
              <h2 className="typewriter">How we work... :)</h2>
              <p className="typewriter">
                Check Our Latest Video to know how we work
              </p>
            </center>
          </div>
          <div className="contentHolder">
            <Button size="large" onClick={this.showModal}>
              <i className="fas fa-play"></i>
            </Button>
          </div>
          <Modal
          className="video"
            title="Our Team Work"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer={null}
            destroyOnClose={true}
          >
            <iframe
            // className="video"
              title="Our Team Work"
              width="100%"
              height="350"
              src={video}
            ></iframe>
          </Modal>
        </div>
      </div>
    );
  }
}

export default AppWorks;
