import React, { useState } from "react";
import { Anchor, Button } from "antd";
import Logo from "../../assets/images/Logo.png";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

const { Link } = Anchor;

function AppHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="container-fluid">
      <div className="header" style={{ height: "6rem" }}>
        <img
          src={Logo}
          style={{ width: "18%", marginTop: "0.9%" }}
          className="logo"
          alt="Logo"
        />

        {/* Desktop Menu */}
        <div className="desktopMenu">
          <Anchor targetOffset="53">
            <Link href="#hero" title="Home" />
            <Link href="#about" title="About" />
            <Link href="#portfolio" title="Portfolio" />
            <Link href="#works" title="Work" />
            <Link href="#faq" title="FAQ" />
            <Link href="#pricing" title="Pricing" />
            <Link href="#contact" title="Contact" />
          </Anchor>
        </div>

        {/* Animated Text (Optional) */}
        <div className="waviy">
          <span style={{ "--i": 1 }}>D</span>
          <span style={{ "--i": 2 }}>i</span>
          <span style={{ "--i": 3 }}>g</span>
          <span style={{ "--i": 4 }}>i</span>&nbsp;
          <span style={{ "--i": 5 }}>S</span>
          <span style={{ "--i": 6 }}>t</span>
          <span style={{ "--i": 7 }}>i</span>
          <span style={{ "--i": 8 }}>t</span>
          <span style={{ "--i": 9 }}>c</span>
          <span style={{ "--i": 10 }}>h</span>
        </div>

        {/* Mobile Menu Icon */}
        <div className="mobileMenuIcon">
          <Button
            type="primary"
            onClick={toggleMenu}
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
          >
            {isMenuOpen ? (
              <CloseOutlined style={{ fontSize: "1.5rem", color: "#fff" }} />
            ) : (
              <MenuOutlined style={{ fontSize: "1.5rem", color: "#fff" }} />
            )}
          </Button>
        </div>

        {/* Mobile Dropdown Menu */}
        {isMenuOpen && (
          <div className="mobileDropdownMenu">
            <Anchor targetOffset="60">
              <Link href="#hero" title="Home" onClick={toggleMenu} />
              <Link href="#about" title="About" onClick={toggleMenu} />
              <Link href="#portfolio" title="Portfolio" onClick={toggleMenu} />
              <Link href="#works" title="Work" onClick={toggleMenu} />
              <Link href="#faq" title="FAQ" onClick={toggleMenu} />
              <Link href="#pricing" title="Pricing" onClick={toggleMenu} />
              <Link href="#contact" title="Contact" onClick={toggleMenu} />
            </Anchor>
          </div>
        )}
      </div>
    </div>
  );
}

export default AppHeader;
